<template>
  <button
    v-if="loadOnClick"
    class="btn"
    :class="cssClass"
    type="button"
    :disabled="isDisabled"
    @click="onClick"
  >
    <span
      v-if="isLoading"
      class="spinner-border spinner-border-sm"
      :class="$style.spinner"
      role="status"
      aria-hidden="true"
    ></span>

    <span :class="$style.label">{{ label }}</span>

    <slot></slot>
  </button>

  <button
    v-else
    class="btn"
    :class="cssClass"
    type="button"
    :disabled="isDisabled"
    @click="onClickSimple"
  >
    <span
      v-if="loading"
      class="spinner-border spinner-border-sm"
      :class="$style.spinner"
      role="status"
      aria-hidden="true"
    ></span>

    <span :class="$style.label">{{ label }}</span>

    <slot></slot>
  </button>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UiKitButton',

  props: {
    label: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    loadOnClick: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'click',
  ],

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    isDisabled() {
      return this.disabled || this.loading || this.isLoading
    },

    cssClass() {
      return [
        this.$style.button,
        {
          [this.$style.loading]: this.loading,
        },
      ]
    },
  },

  methods: {
    onClick() {
      if (this.isLoading) {
        return false
      }

      this.isLoading = true

      this.$emit('click', () => {
        this.isLoading = false
      })

      return true
    },

    onClickSimple(e) {
      this.$emit('click', e)
    },
  },
})
</script>

<style module lang="scss">
.button {
  position: relative;

  &:disabled {
    opacity: 1;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: '';
      background-color: inherit;
      opacity: 0.7;
    }
  }
}

.loading {
  .label {
    opacity: 0;
  }
}

.spinner {
  position: absolute;
  z-index: 2;
  margin: auto;
  inset: 0;
}
</style>
