<template>
  <SlickList
    v-model:list="list"
    :use-drag-handle="useDragHandle"
    :group="group"
    :axis="axis"
    :lock-axis="lockAxis"
    :disabled="isDisabled"
    :distance="distance"
    :class="cssClass"
    :press-delay="pressDelay"
    :use-window-as-scroll-container="useWindowAsScrollContainer"
    @sort-start="sortStart"
    @sort-end="sortEnd"
  >
    <slot />
  </SlickList>
</template>

<script>
import { defineComponent } from 'vue'
import { SlickList } from 'vue-slicksort'

export default defineComponent({
  name: 'UiKitSlickSortList',

  components: {
    SlickList,
  },

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    group: {
      type: String,
      default: 'group',
    },

    axis: {
      type: String,
      default: 'y',
    },

    lockAxis: {
      type: String,
      default: 'y',
    },

    // disatance обязательно должен быть не 0. При 0, элементы не отображаются. Баг SlickList
    distance: {
      type: Number,
      default: 10,
    },

    pressDelay: {
      type: Number,
      default: 0,
    },

    useDragHandle: {
      type: Boolean,
      default: false,
    },

    useWindowAsScrollContainer: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
    'sort-start',
    'sort-end',
  ],

  data() {
    return {
      isMoved: false,
    }
  },

  computed: {
    list: {
      get() {
        return this.modelValue
      },

      set(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    },

    cssClass() {
      return [
        this.$attrs.class,
        this.isMoved ? 'user-select-none' : '',
      ]
    },
  },

  methods: {
    sortStart(value) {
      this.isMoved = true
      this.$emit('sort-start', value)
    },

    sortEnd(value) {
      this.isMoved = false
      this.$emit('sort-end', value)
    },
  },
})
</script>
