import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiApplicationDownloadEnrollmentOrders =
  new (class extends ApiBase {
    /**
     * @param {Object} parameters
     * @returns {Promise}
     */
    get(parameters = {}) {
      return this._GET(
        `/diagnostics/documents/download-enrollment-orders/?${UrlHelper.param(parameters)}`,
      )
    }
  })()
