import DownloadHelper from '@/helpers/DownloadHelper'
import { ApiAnalyticsOnlineLessonChart } from 'ApiRest/Api/Analytics/OnlineLesson/Chart'
import { ApiAnalyticsOnlineLessonCommon } from 'ApiRest/Api/Analytics/OnlineLesson/Common'
import { ApiAnalyticsOnlineLessonData } from 'ApiRest/Api/Analytics/OnlineLesson/Data'
import { ApiAnalyticsOnlineLessonDetail } from 'ApiRest/Api/Analytics/OnlineLesson/Detail'
import { ApiAnalyticsOnlineLessonJournal } from 'ApiRest/Api/Analytics/OnlineLesson/Journal'
import { ApiAnalyticsOnlineLessonUsers } from 'ApiRest/Api/Analytics/OnlineLesson/Users'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchChart({ commit, state }, filters) {
    const { data } = await ApiAnalyticsOnlineLessonChart.get({
      ...filters,
      period: state.detailPeriod.value,
    })

    commit('setChart', data)
  },

  async fetchCommon({ state, commit }, filters) {
    const { data } = await ApiAnalyticsOnlineLessonCommon.get({
      ...filters,
      period: state.detailPeriod.value,
    })

    commit('setCommon', data)
  },

  async fetchData({ state, commit }, filters) {
    const { data } = await ApiAnalyticsOnlineLessonData.get({
      ...filters,
      period: state.detailPeriod.value,
    })

    commit('setData', data)
  },

  async fetchDetail({ state, commit }, filters) {
    const { data } = await ApiAnalyticsOnlineLessonDetail.get({
      ...filters,
      period: state.detailPeriod.value,
    })

    commit('setDetail', data)
  },

  async fetchUsers({ state, commit }, filters) {
    const { data } = await ApiAnalyticsOnlineLessonUsers.get({
      ...filters,
      period: state.detailPeriod.value,
    })

    commit('setUsers', data)
  },

  async downloadJournal(context, filters) {
    const response = await ApiAnalyticsOnlineLessonJournal.get(filters)

    DownloadHelper.downloadBlob(response)
  },
}
