import ApiBase from 'ApiRest/ApiBase'

export const ApiSMSLog = new (class extends ApiBase {
  /**
   * Получение логов смс-кодов
   * @returns {Promise<Object>}
   */
  get() {
    return this._GET(`/auth/two-factor-auth-sms-log/get-log`)
  }
})()
