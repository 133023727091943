export const userActions = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',

  READ_LIBRARY_PROOFREADING: 'read_library_proofreading',
  READ_LIBRARY_FIX_REQUIRED: 'read_library_fix_required',
  READ_LIBRARY_MODERATION: 'read_library_moderation',
  READ_LIBRARY_APPROVED: 'read_library_approved',

  WRITE_LIBRARY_COMMON: 'write_library_common',
  WRITE_LIBRARY_PRIVATE: 'write_library_private',

  READ_STREAMS: 'read_streams',

  READ_MENU_ITEM: 'read_menu_item',

  RUN_BIG_BLUE_BUTTON_SHOWCASE_LESSON: 'showcase_online_lessons_view',

  FULL_UPDATE_ONLINE_LESSONS: 'online_lessons_full_update',
  VIDEO_UPDATE_ONLINE_LESSONS: 'online_lessons_video_update',

  UPDATE_STATEMENT_MANUAL_MARK: 'update_statement_manual_mark',
  UPDATE_STATEMENT_UPLOAD: 'update_statement_upload',

  // Редактирование количества голосов раздела поддержки
  UPDATE_SUPPORT_VOTE_COUNT: 'update_support_vote_count',
  // Редактирование менеджерских полей раздела поддержка - деталка вопрос
  UPDATE_SUPPORT_QUESTION_MANAGER_FIELDS:
    'update_support_question_manager_fields',
  // Редактирование департаментов раздела поддержки
  UPDATE_SUPPORT_QUESTION_DEPARTMENTS: 'update_support_question_departments',

  // Редактирование признака "Активная школа"
  UPDATE_ACTIVE_SCHOOL: 'update_active_school',

  // Редактирование доступа в Физикон
  // TODO: https://iblshool.atlassian.net/browse/I20-3770?focusedCommentId=17633
  UPDATE_ACCESS_PHYSICON: 'students_physicon',

  // Загрузка студентов СО
  STUDENTS_ACCESS_UPLOAD_FE: 'students_allow_upload_fe',

  // Загрузка студентов ЗО
  STUDENTS_ACCESS_UPLOAD_EXTRAMURAL: 'students_allow_upload_extramural',

  // Редактирование признака "Тестовый клиент"
  UPDATE_CLIENT_TEST_FLAG: 'update_client_test_flag',

  // Редактирование признака "Тестовый ученик"
  UPDATE_STUDENT_TEST_FLAG: 'update_student_test_flag',

  // Добавление и удаление модулей школы
  UPDATE_SCHOOL_MODULES: 'update_school_modules',

  // Разблокировка сотрудника
  UNBLOCK_USER: 'unblock_user',

  // Отправка договора в 1С
  UPDATE_CONTRACT_SEND_1C: 'update_contract_send_1C',

  // Изменение картинки КТП для ЦОК
  UPDATE_CTP_COVER_FILE: 'update_ctp_cover_file',

  // Активация функционала чата
  ENABLE_CHAT: 'enable_chat',

  // Доступ к персональным данным клиента
  ACCESS_PD_CLIENT: 'access_pd_client',
  // Доступ к персональным данным ученика
  ACCESS_PD_STUDENT: 'access_pd_student',

  // Просмотр библиотеки ученика
  READ_SCHOOL_LIBRARY: 'read_school_library',

  // Ограничение редактирования периодов ведомости ОО
  DISABLING_STATEMENT_PERIODS: 'disabling_statement_periods',

  // Отображение кнопки скачивания таблицы заявок входящей диагностики
  DOWNLOAD_DIAGNOSTICS_APPLICATIONS: 'income_diagnostics_download_button',

  // Платежи - Счета. Отображение кнопки скачивания таблицы счетов
  INVOICES_EXCEL_EXPORT: 'invoices_excel_export',

  // Платежи - Счета. Возможность изменять сумму в счете
  INVOICES_CHANGE: 'invoices_change',

  INVOICES_CHANGE_STATUS_CHECKBOX: 'invoices_change_status_checkbox',

  // Аналитика по онлайн-урокам ДО
  // Пользователь может свободно выстраивать аналитику по посещениям и по просмотрам записей онлайн-уроков;
  // В detail-школе вкладка “Документы” - есть возможность загрузить шаблон “Отчета посещения дополнительных занятий”;
  // В подразделе “Аналитика онлайн-урока” есть возможность выгрузить журнал ДО при нажатии на кнопку “Выгрузить журнал ДО”
  ANALYTICS_ONLINE_LESSON_DO: 'analytics_online_lesson_do',

  // Админка - Онлайн уроки - Деталка: Отображение вкладок: Записавшиеся, Посетившие, Просмотревшие.
  ONLINE_LESSON_DETAIL_ANALYTICS: 'online_lesson_detail_analytics',

  // Админка - Клиенты. Функционал выгрузки клиентов
  CLIENTS_EXCEL_EXPORT: 'clients_export',

  // Админка - Ведомость. Рассчитать средний балл
  STATEMENT_RECALC_MARK: 'statement_recalc_mark',
}
