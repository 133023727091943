const routes = [
  {
    path: 'administrating-zoom',
    name: 'admin-administrating-zoom',
    meta: {
      accessControlGroup: 'administrating-zoom',
    },
    component: () => import('@/pages/administrating/StudentsZoom.vue'),
  },
  {
    path: 'administrating-zoom/:studentId(\\d+)',
    name: 'admin-administrating-zoom-the-student',
    meta: {
      accessControlGroup: 'administrating-zoom',
    },
    component: () => import('@/pages/administrating/TheStudentZoom.vue'),
  },

  {
    path: 'administrating-educont-log',
    name: 'admin-administrating-educont-log',
    component: () => import('@/pages/administrating/EducontLog.vue'),
  },

  {
    path: 'administrating-sms-log',
    name: 'admin-administrating-sms-log',
    component: () => import('@/pages/administrating/SMSLog.vue'),
  },
]

export default routes
