import Constants from 'Constants'

const GRADE_FOR_CERTIFICATE_SCAN = 10

export default {
  getCertificationTests(state) {
    return state.theRequest.certification.tests.map((test) => ({
      id: test.id,
      name: test.test.name,
      group: test.group,
      subject: test.test.subject,
    }))
  },

  getMainRequiredCertificationTests(state, getters) {
    return getters.getCertificationTests.filter(
      (test) =>
        test.group.id ===
        Constants.familyEducationCertificationTestGroup.MAIN_REQUIRED,
    )
  },

  getRequiredCertificationTests(state, getters) {
    return getters.getCertificationTests.filter(
      (test) =>
        test.group.id ===
        Constants.familyEducationCertificationTestGroup.REQUIRED,
    )
  },

  getOptionalCertificationTests(state, getters) {
    const optionalCertificationTests = getters.getCertificationTests.filter(
      (test) =>
        test.group.id ===
        Constants.familyEducationCertificationTestGroup.OPTIONAL,
    )

    return optionalCertificationTests.reduce((prev, next) => {
      const hasTest = prev.some((test) => test.id === next.id)

      if (!hasTest) {
        const filteredTestsBySubject = optionalCertificationTests.filter(
          (test) => test.subject.id === next.subject.id,
        )

        prev.push(...filteredTestsBySubject)
      }

      return [
        ...prev,
      ]
    }, [])
  },

  isCertificateScanFileAvailable(state) {
    return (
      state.theRequest.certification.grade?.id === GRADE_FOR_CERTIFICATE_SCAN
    )
  },
}
