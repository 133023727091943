export default () => ({
  subjects: [],
  subjectsWithMarks: [],
  disciplineList: [],
  subjectsWithAverageMarks: [],
  testingAttempts: [],
  testingDynamics: [],
  platformVisit: null,
  ctpsData: [],
  materialViews: null,
  spentTimeOnPlatform: 0,
  scienceAverageMarks: [],
  options: {
    academicYearPresets: [],
  },
  filters: {
    studentId: null,
    academicYearPresetId: null,
    period: {
      startDate: null,
      endDate: null,
    },
    selectedSubjects: [],
  },
})
