/**
 * @typedef {Object} UiKitNavigation
 * @property {string} id - уникальный id
 * @property {string} icon - Иконка
 * @property {string} text - Текст
 * @property {string} [visible] - Видимость
 * @property {number} [notifications] - Кол-во уведомлений
 * @property {UiKitNavigation[]} [items] - Список вложенных элементов
 */

import { defineAsyncComponent } from 'vue'

export default () => ({
  /**
   * @type {UiKitNavigation[]}
   */
  navigation: [
    {
      id: 'admin-main',
      icon: 'fas fa-home',
      text: 'nav.main',
      routeName: 'admin-main',
    },

    {
      id: 'admin-messages',
      icon: 'fas fa-comment',
      text: 'nav.messages',
      routeName: 'admin-messages',
    },

    {
      id: 'admin-library',
      icon: 'fas fa-book-open',
      text: 'nav.library',
      visible: false,
      items: [
        {
          id: 'admin-library-subjects',
          routeName: 'admin-library-subjects',
          text: 'nav.subjects',
        },
        {
          id: 'admin-library-training-programs',
          routeName: 'admin-library-training-programs',
          text: 'nav.training_programs',
        },
        {
          id: 'admin-library-sections',
          routeName: 'admin-library-sections',
          text: 'nav.sections',
        },
        {
          id: 'admin-library-etags',
          routeName: 'admin-library-etags',
          text: 'nav.etag',
        },
        {
          id: 'moderation',
          text: 'nav.moderation',
          visible: false,
          items: [
            {
              id: 'admin-library-moderation-contents',
              routeName: 'admin-library-moderation-contents',
              text: 'nav.contents',
            },
            {
              id: 'admin-library-moderation-questions',
              routeName: 'admin-library-moderation-questions',
              text: 'nav.questions',
            },
          ],
        },
        {
          id: 'admin-library-trash',
          text: 'nav.trash',
          visible: false,
          items: [
            {
              id: 'admin-library-trash-topics',
              routeName: 'admin-library-trash-topics',
              text: 'nav.topics',
            },
            {
              id: 'admin-library-trash-contents',
              routeName: 'admin-library-trash-contents',
              text: 'nav.contents',
            },
            {
              id: 'admin-library-trash-questions',
              routeName: 'admin-library-trash-questions',
              text: 'nav.questions',
            },
          ],
        },
      ],
    },

    {
      id: 'admin-tests-parent',
      icon: 'fas fa-check-double',
      text: 'nav.tests',
      visible: false,
      items: [
        {
          id: 'admin-tests',
          text: 'nav.tests',
          routeName: 'admin-tests',
        },
        {
          id: 'admin-tests-check',
          text: 'nav.tests_check',
          routeName: 'admin-tests-check',
        },
        {
          id: 'admin-homeworks',
          text: 'nav.homeworks',
          routeName: 'admin-homeworks',
        },
        {
          id: 'admin-tests-payment',
          text: 'nav.payment_tests',
          routeName: 'admin-tests-payment',
        },
      ],
    },

    {
      id: 'admin-ctps',
      icon: 'fas fa-tasks',
      text: 'nav.ctps',
      routeName: 'admin-ctps',
    },

    {
      id: 'admin-online-school',
      icon: 'fas fa-graduation-cap',
      text: 'nav.online_school',
      visible: false,
      items: [
        {
          id: 'admin-online-school-settings',
          routeName: 'admin-online-school-settings',
          text: 'nav.online_school_settings',
        },
        {
          id: 'admin-online-school-orders',
          routeName: 'admin-online-school-orders',
          text: 'nav.online_school_orders',
        },
        {
          id: 'admin-online-school-products',
          routeName: 'admin-online-school-products',
          text: 'nav.online_school_products',
        },
        {
          id: 'admin-online-school-product-requests',
          routeName: 'admin-online-school-product-requests',
          text: 'nav.online_school_product_requests',
        },
        {
          id: 'admin-online-school-call-requests',
          routeName: 'admin-online-school-call-requests',
          text: 'nav.online_school_call_requests',
        },
      ],
    },

    {
      id: 'admin-certifications',
      icon: 'fas fa-graduation-cap',
      text: 'nav.certification',
      routeName: 'admin-certifications',
      visible: false,
      items: [
        {
          id: 'admin-the-certification-create',
          routeName: 'admin-the-certification-create',
          text: 'nav.certifications_create',
        },
      ],
    },

    {
      id: 'admin-family-certifications-parent',
      icon: 'fas fa-graduation-cap',
      text: 'nav.family_certifications',
      visible: false,
      items: [
        {
          id: 'admin-family-certifications',
          routeName: 'admin-family-certifications',
          text: 'nav.certifications',
        },
        {
          id: 'admin-family-certifications-schedule',
          routeName: 'admin-family-certifications-schedule',
          text: 'nav.certifications_schedule',
        },
        {
          id: 'admin-family-certifications-statement',
          routeName: 'admin-family-certification-statement',
          text: 'nav.statement',
        },
      ],
    },

    {
      id: 'admin-income-diagnostics',
      icon: 'fas fa-graduation-cap',
      text: 'nav.income_diagnostics',
      visible: false,
      items: [
        {
          id: 'admin-income-diagnostics-applications',
          routeName: 'admin-income-diagnostics-applications',
          text: 'nav.requests',
        },
      ],
    },

    {
      id: 'admin-statement',
      icon: 'fas fa-address-book',
      text: 'nav.statement',
      routeName: 'admin-the-statement',
      visible: false,
    },

    {
      id: 'admin-the-full-time-statement',
      icon: 'fas fa-address-book',
      text: 'nav.full_time_statement',
      routeName: 'admin-the-full-time-statement',
      visible: false,
    },

    {
      id: 'admin-special-statement',
      icon: 'fas fa-address-book',
      text: 'nav.special_statement',
      routeName: 'admin-special-statement',
      visible: false,
    },

    {
      id: 'admin-analytics',
      icon: 'fas fa-chart-bar',
      text: 'nav.analytics',
      routeName: 'admin-analytics',
      visible: false,
      items: [
        {
          id: 'admin-analytics-student-enrolled',
          routeName: 'admin-analytics-student-enrolled',
          text: 'nav.student_enrolled',
        },

        {
          id: 'admin-analytics-ctp',
          routeName: 'admin-analytics-ctp',
          text: 'nav.analytics_ctp',
        },

        {
          id: 'admin-analytics-library',
          routeName: 'admin-analytics-library',
          text: 'nav.analytics_library',
        },

        {
          id: 'admin-analytics-online-lessons',
          routeName: 'admin-analytics-online-lessons',
          // TODO: i18n
          text: 'Аналитика online-уроков',
        },
      ],
    },

    {
      id: 'admin-students',
      icon: 'fas fa-user-graduate',
      text: 'nav.students',
      routeName: 'admin-students',
      visible: false,
      items: [
        {
          id: 'admin-students-demo',
          routeName: 'admin-students-demo',
          text: 'nav.students_demo',
        },
        {
          id: 'admin-students-educont',
          routeName: 'admin-students-educont',
          text: 'nav.educont',
        },
        {
          id: 'admin-student-group-distribution',
          routeName: 'admin-student-group-distribution',
          text: 'nav.group_distribution',
        },
        {
          id: 'admin-student-transfer',
          routeName: 'admin-student-transfer',
          text: 'nav.transfer',
        },
        {
          id: 'admin-student-streams',
          routeName: 'admin-student-streams',
          text: 'nav.streams',
        },
        {
          id: 'admin-student-groups',
          routeName: 'admin-student-groups',
          text: 'nav.groups',
        },
        {
          id: 'admin-students-trash',
          routeName: 'admin-students-trash',
          text: 'nav.trash',
        },
      ],
    },

    {
      id: 'admin-news',
      icon: 'fas fa-newspaper',
      text: 'nav.news',
      routeName: 'admin-news',
    },

    {
      id: 'admin-events',
      icon: 'fas fa-calendar-alt',
      text: 'nav.events',
      routeName: 'admin-events',
    },

    {
      id: 'admin-teenology',
      icon: 'fas fa-envelope',
      text: 'Teenology',
      routeName: 'admin-teenology',
    },

    {
      id: 'admin-sendings',
      icon: 'fas fa-envelope',
      text: 'nav.sendings',
      routeName: 'admin-sendings',
    },

    {
      id: 'admin-rating',
      icon: 'fas fa-chart-line',
      text: 'nav.rating',
      routeName: 'admin-rating',
    },

    {
      id: 'admin-credits',
      icon: 'fas fa-chart-line',
      text: 'nav.credits',
      routeName: 'admin-credits',
    },

    {
      id: 'admin-online-lessons',
      icon: 'fas fa-globe',
      text: 'nav.online_lessons',
      routeName: 'admin-online-lessons',
      visible: false,
      items: [
        {
          id: 'admin-online-lesson-rooms',
          routeName: 'admin-online-lesson-rooms',
          text: 'nav.rooms',
        },
      ],
    },

    {
      id: 'admin-showcase-online-lessons',
      icon: 'fas fa-globe',
      // TODO: i18n
      text: 'Online-уроки витрины',
      routeName: 'admin-showcase-online-lessons',
    },

    {
      id: 'admin-enrollment-requests',
      icon: 'fas fa-file',
      text: 'nav.requests',
      routeName: 'admin-enrollment-requests',
      visible: false,
      items: [
        {
          id: 'admin-enrollment-requests-draft',
          routeName: 'admin-enrollment-requests-draft',
          text: 'nav.draft',
        },

        {
          id: 'admin-enrollment-requests-trash',
          routeName: 'admin-enrollment-requests-trash',
          text: 'nav.trash',
        },
      ],
    },

    {
      id: 'admin-enrollment-contracts',
      icon: 'fas fa-file-alt',
      text: 'nav.contracts',
      routeName: 'admin-enrollment-contracts',
    },

    {
      id: 'admin-family-certification-requests',
      icon: 'fas fa-file-alt',
      text: 'nav.family_certification_requests',
      routeName: 'admin-family-certification-requests',
    },

    {
      id: 'admin-clients',
      icon: 'fas fa-user-tie',
      text: 'nav.clients',
      routeName: 'admin-clients',
      visible: false,
      items: [
        {
          id: 'admin-leads',
          routeName: 'admin-leads',
          text: 'nav.leads',
        },
        {
          id: 'admin-clients-trash',
          routeName: 'admin-clients-trash',
          text: 'nav.trash',
        },
      ],
    },

    {
      id: 'admin-course-management-parent',
      icon: 'fas fa-shopping-bag',
      // TODO: i18n
      text: 'Мои курсы',
      visible: false,
      items: [
        {
          id: 'admin-course-management-products',
          routeName: 'admin-course-management-products',
          // TODO: i18n
          text: 'Продукты',
        },
        {
          id: 'admin-course-management-requests',
          routeName: 'admin-course-management-requests',
          // TODO: i18n
          text: 'Заявки',
        },
        {
          id: 'admin-course-management-orders',
          routeName: 'admin-course-management-orders',
          // TODO: i18n
          text: 'Заказы',
        },
        {
          id: 'admin-course-management-analytics',
          routeName: 'admin-course-management-analytics',
          // TODO: i18n
          text: 'Аналитика',
        },
        {
          id: 'admin-course-management-categories',
          routeName: 'admin-course-management-categories',
          // TODO: i18n
          text: 'Категории',
        },
        {
          id: 'admin-course-management-groups',
          routeName: 'admin-course-management-groups',
          // TODO: i18n
          text: 'Группы',
        },
        {
          id: 'admin-course-management-banners',
          routeName: 'admin-course-management-banners',
          // TODO: i18n
          text: 'Настройка баннеров',
        },
        {
          id: 'admin-course-management-parameters',
          routeName: 'admin-course-management-parameters',
          // TODO: i18n
          text: 'Настройка параметров',
        },
      ],
    },

    {
      id: 'admin-product-management-parent',
      icon: 'fas fa-shopping-bag',
      text: 'nav.product_management',
      visible: false,
      items: [
        {
          id: 'admin-product-management-products',
          routeName: 'admin-product-management-products',
          text: 'nav.products',
        },
        {
          id: 'admin-product-management-requests',
          routeName: 'admin-product-management-requests',
          // TODO: i18n
          text: 'Заявки',
        },
        {
          id: 'admin-product-management-questions',
          routeName: 'admin-product-management-questions',
          // TODO: i18n
          text: 'Вопросы',
        },
        {
          id: 'admin-product-management-orders',
          routeName: 'admin-product-management-orders',
          // TODO: i18n
          text: 'Заказы',
        },
        {
          id: 'admin-product-management-analytics',
          routeName: 'admin-product-management-analytics',
          text: 'nav.analytics',
        },
        {
          id: 'admin-product-management-categories',
          routeName: 'admin-product-management-categories',
          // TODO: i18n
          text: 'Категории',
        },
        {
          id: 'admin-product-management-groups',
          routeName: 'admin-product-management-groups',
          // TODO: i18n
          text: 'Группы',
        },
        {
          id: 'admin-product-management-navigation',
          routeName: 'admin-product-management-navigation',
          // TODO: i18n
          text: 'Навигация',
        },
        {
          id: 'admin-product-management-banners',
          routeName: 'admin-product-management-banners',
          // TODO: i18n
          text: 'Настройка баннеров',
        },
        {
          id: 'admin-product-management-parameters',
          routeName: 'admin-product-management-parameters',
          // TODO: i18n
          text: 'Настройка параметров',
        },
        {
          id: 'admin-product-management-advertisements',
          routeName: 'admin-product-management-advertisements',
          // TODO: i18n
          text: 'Реклама',
        },
        {
          id: 'admin-product-management-settings',
          routeName: 'admin-product-management-settings',
          // TODO: i18n
          text: 'Настройки',
        },
      ],
    },

    {
      id: 'admin-payment-parent',
      icon: 'fas fa-coins',
      text: 'nav.payments',
      visible: false,
      items: [
        {
          id: 'admin-payment-invoices',
          routeName: 'admin-payment-invoices',
          text: 'nav.invoices',
        },
        {
          id: 'admin-payment-services',
          routeName: 'admin-payment-services',
          text: 'Услуги',
        },
        {
          id: 'admin-payment-promocode-groups',
          routeName: 'admin-payment-promocode-groups',
          text: 'nav.promocodes',
        },
        {
          id: 'admin-payment-product-promocode-groups',
          routeName: 'admin-payment-product-promocode-groups',
          text: 'nav.automatic_promocodes',
        },
        {
          id: 'admin-payment-subscriptions',
          routeName: 'admin-payment-subscriptions',
          text: 'nav.subscribes',
        },
      ],
    },

    {
      id: 'admin-price-parent',
      icon: 'fas fa-money-bill-wave',
      text: 'nav.price',
      visible: false,
      items: [
        {
          id: 'admin-price-education-parent',
          text: 'nav.education',
          visible: false,
          items: [
            {
              id: 'admin-price-education-extramural-parent',
              text: 'nav.extramural',
              visible: false,
              items: [
                {
                  id: 'admin-price-extramural-subsidy',
                  routeName: 'admin-price-extramural-subsidy',
                  text: 'nav.subsidy',
                },
                {
                  id: 'admin-price-extramural-individual-subsidy',
                  routeName: 'admin-price-extramural-individual-subsidy',
                  text: 'nav.individual_subsidy',
                },
                {
                  id: 'admin-price-extramural-paid',
                  routeName: 'admin-price-extramural-paid',
                  text: 'nav.paid',
                },
                {
                  id: 'admin-price-extramural-individual-paid',
                  routeName: 'admin-price-extramural-individual-paid',
                  text: 'nav.individual_paid',
                },
              ],
            },
          ],
        },
      ],
    },

    {
      id: 'admin-support',
      icon: 'fas fa-headset',
      text: 'nav.support',
      routeName: 'admin-support',
      visible: false,
      notifications: 0,
      append: {
        component: defineAsyncComponent(
          () => import('@/components/support/question/QuestionsCount.vue'),
        ),
      },
    },

    {
      id: 'admin-settings-parent',
      icon: 'fas fa-sliders-h',
      text: 'nav.settings',
      visible: false,
      items: [
        {
          id: 'admin-settings-schools',
          routeName: 'admin-settings-schools',
          text: 'nav.schools',
        },
        {
          id: 'admin-family-educations',
          routeName: 'admin-family-educations',
          text: 'nav.family_educations',
        },
        {
          id: 'admin-settings-academic-years',
          routeName: 'admin-settings-academic-years',
          text: 'nav.academic_years',
        },
        {
          id: 'admin-settings-timetables',
          routeName: 'admin-settings-timetables',
          text: 'nav.timetables',
        },
        {
          id: 'admin-settings-holidays',
          routeName: 'admin-settings-holidays',
          text: 'nav.holidays',
        },
        {
          id: 'admin-settings-partners',
          routeName: 'admin-settings-partners',
          text: 'nav.partners',
        },
        {
          id: 'admin-settings-roles',
          routeName: 'admin-settings-roles',
          text: 'nav.roles',
        },
        {
          id: 'admin-settings-users',
          routeName: 'admin-settings-users',
          text: 'nav.users',
        },
        {
          id: 'admin-settings-wiki',
          routeName: 'admin-settings-wiki',
          text: 'nav.wiki',
        },
        {
          id: 'admin-settings-trigger-sendings',
          routeName: 'admin-settings-trigger-sendings',
          text: 'nav.notification_center',
        },
      ],
    },

    {
      id: 'admin-administrating-parent',
      icon: 'fas fa-user-cog',
      text: 'nav.administrating',
      visible: false,
      items: [
        {
          id: 'admin-administrating-zoom',
          routeName: 'admin-administrating-zoom',
          text: 'nav.zoom',
        },
        {
          id: 'admin-administrating-educont-log',
          routeName: 'admin-administrating-educont-log',
          text: 'nav.log_educont',
        },
        {
          id: 'admin-administrating-sms-log',
          routeName: 'admin-administrating-sms-log',
          text: 'nav.sms_log',
        },
      ],
    },
  ],

  profile: {
    firstName: null,
    lastName: null,
    middleName: null,
    id: null,
    email: null,
  },

  textsLoaded: false,

  activeModules: null,

  askQuestionCounters: {},

  isBarActive: true,
})
