export default {
  filtersCtpList(state) {
    const { studentId, academicYearPresetId } = state.filters

    return {
      studentId,
      academicYearPresetId,
    }
  },

  filters(state) {
    const { studentId, academicYearPresetId, period } = state.filters

    return {
      studentId,
      academicYearPresetId,
      period,
    }
  },

  hasSelectedSubjects(state) {
    return state.filters.selectedSubjects.length > 0
  },

  hasSubjects(state) {
    return state.subjects.length > 0
  },
}
