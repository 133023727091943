import ApiContentList from 'ApiRest/Api/Library/Section/Topic/Content/List/List'

const MAX_ROWS_PER_PAGE = 200 // лимит на беке

export default {
  async fetchContentList({ commit }, params) {
    const payload = {
      ...params,
      rowsPerPage: MAX_ROWS_PER_PAGE,
    }
    const { data } = await ApiContentList.get(payload)

    commit('setFilters', params)
    commit(
      'setContentList',
      data.sort((a, b) => a.position - b.position),
    )
  },
}
