import ApiOption from 'ApiRest/Api/Option'
import { ApiStudentLearningAnalyticsAverageMarks } from 'ApiRest/Api/Student/LearningAnalytics/AverageMarks'
import { ApiStudentLearningAnalyticsCtpList } from 'ApiRest/Api/Student/LearningAnalytics/CtpList'
import { ApiStudentLearningAnalyticsDiscipline } from 'ApiRest/Api/Student/LearningAnalytics/Discipline'
import { ApiStudentLearningAnalyticsMarksCount } from 'ApiRest/Api/Student/LearningAnalytics/MarksCount'
import { ApiStudentLearningAnalyticsScienceAverageMarks } from 'ApiRest/Api/Student/LearningAnalytics/ScienceAverageMarks'
import { ApiStudentLearningAnalyticsAttempts } from 'ApiRest/Api/Student/LearningAnalytics/TestingAttempts'
import { ApiStudentLearningAnalyticsTestingDynamics } from 'ApiRest/Api/Student/LearningAnalytics/TestingDynamics'
import { ApiStudentLearningAnalyticsVisitingAggregated } from 'ApiRest/Api/Student/LearningAnalytics/VisitingAggregated'
import ApiUserSettings from 'ApiRest/Api/UserSettings'

export default {
  async fetchCtpList({ getters, commit }) {
    const { data } = await ApiStudentLearningAnalyticsCtpList.get(
      getters.filtersCtpList,
    )

    commit('setSubjects', data)
  },

  async fetchMarksCount({ getters, commit }) {
    const { data } = await ApiStudentLearningAnalyticsMarksCount.get(
      getters.filters,
    )

    commit('setSubjectsWithMarks', data)
  },

  async fetchDiscipline({ getters, commit }) {
    const { data } = await ApiStudentLearningAnalyticsDiscipline.get(
      getters.filters,
    )

    commit('setDisciplineList', data)
  },

  async fetchAverageMarks({ getters, commit }) {
    const { data } = await ApiStudentLearningAnalyticsAverageMarks.get(
      getters.filters,
    )

    commit('setSubjectsWithAverageMarks', data)
  },

  async fetchTestingAttempts({ getters, commit }) {
    const { data } = await ApiStudentLearningAnalyticsAttempts.get(
      getters.filters,
    )

    commit('setTestingAttempts', data)
  },

  async fetchTestingDynamics({ getters, commit }) {
    const { data } = await ApiStudentLearningAnalyticsTestingDynamics.get(
      getters.filters,
    )

    commit('setTestingDynamics', data)
  },

  async fetchVisitingAggregated({ getters, commit }) {
    const { data } = await ApiStudentLearningAnalyticsVisitingAggregated.get(
      getters.filters,
    )

    commit('setPlatformVisit', data.platformVisit)
    commit('setCtpsData', data.ctpsData)
    commit('setSpentTimeOnPlatform', data.spentTimeOnPlatform)
    commit('setMaterialViews', data.materialViews)
  },

  async fetchScienceAverageMarks({ getters, commit }) {
    const { data } = await ApiStudentLearningAnalyticsScienceAverageMarks.get(
      getters.filters,
    )

    commit('setScienceAverageMarks', data)
  },

  async fetchAcademicYearsOptions({ commit }) {
    const { data } = await ApiOption.getAcademicYearPreset()

    commit('setOptionsList', {
      path: 'academicYearPresets',
      value: data,
    })
  },

  async getFiltersState({ state, commit }) {
    const { studentId } = state.filters

    const { data } = await ApiUserSettings.get({
      name: `the-student-${studentId}-analytics-filters-setting`,
    })

    if (data) {
      commit('setFilters', data.data)
    }
  },

  async saveFiltersSetting({ state }) {
    const { studentId } = state.filters

    await ApiUserSettings.put({
      name: `the-student-${studentId}-analytics-filters-setting`,
      data: state.filters,
    })
  },

  async setFilterState({ commit, dispatch }, payload) {
    commit('setFilter', payload)

    dispatch('saveFiltersSetting')
  },
}
