<template>
  <ul class="list-unstyled mt-2">
    <MetricsTooltipItem
      v-for="tracker in trackers"
      :key="tracker.value"
      :tracker="tracker"
    />
  </ul>
</template>

<script setup lang="ts">
import MetricsTooltipItem from '@/components/metrics/MetricsTooltipItem.vue'
import type { MetricsTracker } from '@/types/MetricsTracker.ts'

interface Props {
  trackers?: MetricsTracker[]
}

withDefaults(defineProps<Props>(), {
  trackers: () => [],
})
</script>
