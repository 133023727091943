<template>
  <li class="d-flex flex-wrap align-items-center mb-2">
    <template v-if="isShowingImages">
      <template v-if="localFile.isImage">
        <div class="position-relative">
          <img
            :alt="localFile.name"
            :src="downloadUrl"
            class="upload-many__img mb-2"
          />

          <div :class="$style.imageActions">
            <div
              v-tippy
              content="Редактировать"
              :class="$style.imageAction"
              class="btn btn-secondary"
              @click="onClickBtnEdit"
            >
              <i class="fas fa-pencil-alt"></i>
            </div>

            <div
              v-tippy
              :content="t(`common_components.file_upload_many.rotate_image_to`)"
              :class="$style.imageAction"
              class="btn btn-secondary"
              @click="onClickBtnRotateImage"
            >
              <i class="fas fa-sync"></i>
            </div>
          </div>
        </div>

        <div class="w-100"></div>
      </template>

      <i
        v-else-if="props.file.mime.includes('image')"
        class="fas fa-file-image upload-many__img-icon mr-2"
      ></i>
    </template>

    <span :class="downloadBtnClass" class="btn cursor-default px-0 d-flex">
      <span class="px-3 text-truncate">
        {{ fileName }}
      </span>

      <span
        v-if="isRendered.btnDownload"
        class="px-2 cursor-pointer text-secondary border-left"
        @click="onClickBtnDownload"
      >
        <i class="fas fa-download fa-fw"></i>
      </span>

      <span
        v-if="isRendered.btnRemove"
        class="px-2 cursor-pointer text-secondary border-left"
        @click="onClickBtnRemove"
      >
        <i class="fas fa-times fa-fw"></i>
      </span>
    </span>
  </li>
</template>

<script setup>
import ApiDownload from '@/api/Download'
import { ApiFileImageRotate } from '@/api/rest/Api/File/Image/Rotate'
import ModalConfirmRemoveFileUploadMany from '@/components/ModalConfirmRemoveFileUploadMany.vue'
import ModalEditFileUpload from '@/components/ModalEditFileUpload.vue'
import { cloneDeep } from 'lodash'
import { useModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'

const ROTATE_ANGLE = 90

const props = defineProps({
  file: {
    type: Object,
    required: true,
  },

  isShowingImages: {
    type: Boolean,
    default: false,
  },

  isDownloadable: {
    type: Boolean,
    default: true,
  },

  isConfirmRemove: {
    type: Boolean,
    default: false,
  },

  isClearable: {
    type: Boolean,
    default: true,
  },

  readonly: {
    type: Boolean,
    default: false,
  },

  customDownloadUrl: {
    type: Function,
    default: null,
  },

  downloadBtnClass: {
    type: String,
    default: 'btn-light text-secondary',
  },
})

const emit = defineEmits([
  'add-comment-attachment',
  'remove',
  'rotate',
])

const { t } = useI18n() // use as global scope

const localFile = ref()

watch(
  () => props.file,
  () => {
    localFile.value = cloneDeep(props.file)
  },
  {
    deep: true,
    immediate: true,
  },
)

function emitRemove() {
  emit('remove', localFile.value)
}

/**
 *
 * @param {Object} file
 */
function onAddCommentAttachment(file) {
  emit('add-comment-attachment', file)
}

/**
 *
 * @param {Object} file
 */
function emitRotate(file) {
  emit('rotate', file)
}

const modalRemove = useModal({
  component: ModalConfirmRemoveFileUploadMany,

  attrs: {
    file: localFile,

    onCancel() {
      modalRemove.close()
    },

    onConfirm() {
      emitRemove()
    },
  },
})

const isRendered = computed(() => ({
  btnDownload: props.isDownloadable,
  btnRemove: props.isClearable && !props.readonly,
}))

const imageSrc = computed(() => {
  if (props.customDownloadUrl) {
    return `${props.customDownloadUrl(localFile.value)}`
  }

  return `${ApiDownload.getFileUrl(localFile.value.id)}`
})

const modalEditFileUpload = useModal({
  component: ModalEditFileUpload,

  attrs: {
    file: localFile,
    imageSrc,
    onUploadFile(file) {
      onAddCommentAttachment(file)
    },
  },
})

const downloadUrl = computed(() => {
  // Необходимо добавлять "хвост" для того,
  // чтобы браузер не кешировал изображения во время манипуляций с ними,
  // например, при повороте изображения.
  const hash = Date.now()

  if (props.customDownloadUrl) {
    return `${props.customDownloadUrl(localFile.value)}?${hash}`
  }

  return `${ApiDownload.getFileUrl(localFile.value.id)}?${hash}`
})

const fileName = computed(
  () =>
    localFile.value?.name || t(`common_components.file_upload_many.untitled`),
)

async function onClickBtnEdit() {
  modalEditFileUpload.open()
}

async function onClickBtnRotateImage() {
  const { data } = await ApiFileImageRotate.patch(
    localFile.value.id,
    ROTATE_ANGLE,
  )

  emitRotate(data)
}

async function onClickBtnRemove() {
  if (props.isConfirmRemove) {
    modalRemove.open()
  } else {
    emitRemove()
  }
}

function onClickBtnDownload() {
  window.location.href = downloadUrl.value
}
</script>

<style lang="scss" module>
.imageActions {
  $gutter: 20px;

  position: absolute;
  right: $gutter;
  bottom: $gutter;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
}

.imageAction {
  width: 40px;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
</style>
