export default {
  hasLogs(state) {
    return state.logs.length > 0
  },

  // TODO: https://redmine.ibls.tech/issues/8456 проверить
  lastLog(state, getters) {
    const isSendingDocumentLog =
      getters.hasLogs && getters.logsListType?.sendingDocumentLog?.length

    if (isSendingDocumentLog) {
      return getters.logsListType?.sendingDocumentLog[0]
    }

    return null
  },

  // TODO: https://redmine.ibls.tech/issues/8456 проверить
  getLastRequestConfirmLog(state) {
    if (state.lastRequestConfirmLog?.timestamp) {
      return `(${state.lastRequestConfirmLog?.timestamp})`
    }

    return null
  },
}
