<template>
  <div class="upload-many">
    <Uploader
      v-if="isRendered.uploader"
      :is-document="isDocument"
      :mime-allowed="acceptMime"
      :max-file-size-mb="maxFileSizeMb"
      @error="emitError"
      @upload-file="processUpload"
    >
      <template #default="{ data, actions, events }">
        <div
          :class="{
            'btn-outline-primary': data.dragover,
            'btn-primary': !data.dragover,
          }"
          class="upload-many-dropzone btn btn-lg"
          v-on="events.drag"
          @drop="(e) => onChange(actions, e)"
        >
          <i class="fas fa-upload mr-2"></i>

          <label>
            <input
              ref="upload"
              :accept="acceptFormat"
              type="file"
              @change="(e) => onChange(actions, e)"
              @drop="(e) => onChange(actions, e)"
            />
          </label>

          <span>
            {{ t('select_file') }}
          </span>
        </div>
      </template>
    </Uploader>

    <ul v-show="hasFiles" class="list-unstyled mb-0 mt-4">
      <FileUploadManyItem
        v-for="file in files"
        :key="file.id"
        :file="file"
        :is-showing-images="isShowingImages"
        :is-clearable="isClearable"
        :is-downloadable="isDownloadable"
        :is-confirm-remove="isConfirmRemove"
        :custom-download-url="customDownloadUrl"
        :readonly="readonly"
        class="d-flex flex-wrap align-items-center mb-2"
        @add-comment-attachment="onAddCommentAttachment"
        @remove="onClickBtnRemove"
        @rotate="onClickBtnRotateImage"
      />
    </ul>
  </div>
</template>

<script>
import FileUploadManyItem from '@/components/FileUploadManyItem.vue'
import Uploader from '@/components/Uploader.vue'
import { cloneDeep } from 'lodash'

export default {
  name: 'FileUploadMany',

  components: {
    FileUploadManyItem,
    Uploader,
  },

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },

    acceptFormat: {
      type: String,
      default: '',
    },

    acceptMime: {
      type: Array,
      default: null,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    isDocument: {
      type: Boolean,
      default: false,
    },

    isClearable: {
      type: Boolean,
      default: true,
    },

    isDownloadable: {
      type: Boolean,
      default: true,
    },

    isUploadable: {
      type: Boolean,
      default: true,
    },

    isShowingImages: {
      type: Boolean,
      default: false,
    },

    isPrivate: {
      type: Boolean,
      default: false,
    },

    isConfirmRemove: {
      type: Boolean,
      default: false,
    },

    customDownloadUrl: {
      type: Function,
      default: null,
    },

    customUploadUrl: {
      type: String,
      default: null,
    },

    maxFileSizeMb: {
      type: Number,
      default: 50,
    },
  },

  emits: [
    'add-comment-attachment',
    'error',
    'remove',
    'rotate',
    'update:modelValue',
  ],

  data() {
    return {
      files: [],
    }
  },

  computed: {
    hasFiles() {
      return this.files?.length > 0
    },

    isRendered() {
      return {
        uploader: this.isUploadable && !this.readonly,
      }
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.files = cloneDeep(this.modelValue)
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('update:modelValue', this.files)
    },

    emitRotate() {
      this.$emit('rotate', this.files)
    },

    emitError(message) {
      this.$emit('error', message)
    },

    emitRemove(file) {
      this.$emit('remove', file, this.files)
    },

    onChange(actions, payload) {
      if (this.customUploadUrl === null) {
        return actions.processUploadAny(payload, this.isPrivate)
      }

      return actions.processUploadCustomEndpoint(payload, this.customUploadUrl)
    },

    processUpload(file) {
      if (!this.readonly) {
        this.files.push(file)
        this.emitChange()
      }
    },

    onAddCommentAttachment(file) {
      this.$emit('add-comment-attachment', file)
    },

    removeFile(file) {
      this.emitRemove(file)

      this.files = this.files.filter(({ id }) => id !== file.id)

      this.emitChange()
    },

    /**
     * @param {Object} file
     */
    async onClickBtnRemove(file) {
      if (this.isConfirmRemove) {
        this.emitRemove(file)
      } else {
        this.removeFile(file)
      }
    },

    onClickBtnRotateImage(file) {
      const index = this.files.findIndex(
        (localFile) => localFile.id === file.id,
      )

      this.files.splice(index, 1, file)

      this.emitRotate()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`common_components.file_upload_many.${name}`)
    },
  },
}
</script>
