import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

const formatNames = function (subjectList) {
  return subjectList.map((subject, index, arr) => {
    const { id, type } = subject

    const duplicateIndex = arr.findIndex((s, i) => i !== index && s.id === id)

    if (duplicateIndex !== -1) {
      switch (type) {
        case 'required':
          arr[index].name += ' (Осн.)'
          break
        case 'additional':
          arr[index].name += ' (Доп.)'
          break
        case 'optional':
          arr[index].name += ' (По выбору)'
          break
        case 'courses':
          arr[index].name += ' (Мои курсы)'
          break
        case 'online_school':
          arr[index].name += ' (Онлайн-школа)'
          break

        default:
          break
      }
    }

    return subject
  })
}

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setSubjects(state, payload) {
    state.subjects = formatNames(payload)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setSubjectsWithMarks(state, payload) {
    state.subjectsWithMarks = payload
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setDisciplineList(state, payload) {
    state.disciplineList = payload
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setSubjectsWithAverageMarks(state, payload) {
    state.subjectsWithAverageMarks = formatNames(payload)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setTestingAttempts(state, payload) {
    state.testingAttempts = payload
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setTestingDynamics(state, payload) {
    state.testingDynamics = formatNames(payload)
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   */
  setPlatformVisit(state, payload) {
    state.platformVisit = payload
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setCtpsData(state, payload) {
    state.ctpsData = payload
  },

  /**
   * @param {Object} state
   * @param {number} payload
   */
  setSpentTimeOnPlatform(state, payload) {
    state.spentTimeOnPlatform = payload
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   */
  setMaterialViews(state, payload) {
    state.materialViews = payload
  },

  /**
   * @param {Object} state
   * @param {number} payload
   */
  setScienceAverageMarks(state, payload) {
    state.scienceAverageMarks = payload
  },

  setOptionsList(state, { path, value }) {
    state.options[path] = value
  },

  setFilters(state, payload) {
    state.filters = payload
  },

  setFilter(state, { path, value }) {
    state.filters[path] = value
  },
}
