import ErrorList from '@/components/shared/ErrorList.vue'
import ToastContent from '@/components/shared/ToastContent.vue'
import AxiosHelper from '@/helpers/AxiosHelper'
import { i18n } from '@/i18n/setup'
import { get, isArray, isPlainObject } from 'lodash'
import { defineComponent } from 'vue'
import { POSITION, TYPE, useToast } from 'vue-toastification'

const toast = useToast()

// TODO: Remove "defaultsData" from setFieldErrors().
// TODO: Cleanup all templates.
/**
 * Set field errors and show global notifications.
 * @example
 * import { NotifyMixin } from '@/helpers/mixins/NotifyMixin'
 * ```js
 * new Vue({
 *   render: h => h({
 *     mixins: [NotifyMixin],
 *   }),
 * })
 *
 * this.notifyMethods().process(
 *     ApiEnrollment.putRequestRejected(
 *       this.enrollmentRequestId,
 *       this.detail,
 *     ).then(() => {
 *       this._change("REQUEST_REJECTED")
 *     })
 * )
 *```
 * <uiKit-formField :errors="getFieldErrorByPath(errors, 'path.to.error')" />
 */
export const NotifyMixin = defineComponent({
  data() {
    return {
      /** Field errors */
      errors: null,
    }
  },

  methods: {
    notifyMethods() {
      return {
        /**
         * Show global error
         * @param {string} message
         */
        showError: (
          message = i18n.global.t(
            'helpers.notify_mixin.toasted_message.show_error',
          ),
        ) => {
          toast(
            {
              component: ToastContent,

              props: {
                message,
              },
            },
            {
              type: TYPE.ERROR,
            },
          )
        },

        /**
         * Show global fullscreen error
         * @param {string} message
         * @param {Object} options
         */
        showErrorFullScreen: (
          message = i18n.global.t(
            'helpers.notify_mixin.toasted_message.show_error',
          ),
          options = {},
        ) => {
          const baseOptions = {
            position: POSITION.TOP_CENTER,
            hideProgressBar: true,
            closeOnClick: false,
            draggable: false,
            toastClassName: 'toasted-error-fullscreen',
            timeout: 600000,
            type: TYPE.ERROR,
            ...options,
          }

          let content = message

          if (isArray(message) || isPlainObject(message)) {
            content = {
              component: ErrorList,

              props: {
                errors: message,
              },
            }
          } else {
            content = {
              component: ToastContent,

              props: {
                message,
              },
            }
          }

          toast(content, baseOptions)
        },

        showInfo: (
          message = i18n.global.t(
            'helpers.notify_mixin.toasted_message.show_success',
          ),
        ) => {
          toast(
            {
              component: ToastContent,

              props: {
                message,
              },
            },
            {
              timeout: 10000,
              type: TYPE.INFO,
            },
          )
        },

        /**
         * Show global success
         * @param {string} message
         */
        showSuccess: (
          message = i18n.global.t(
            'helpers.notify_mixin.toasted_message.show_success',
          ),
        ) => {
          toast(
            {
              component: ToastContent,

              props: {
                message,
              },
            },
            {
              timeout: 4000,
              type: TYPE.SUCCESS,
            },
          )
        },

        /**
         * Process HTTP-request (action), set field errors,
         * show global notification when needed.
         * @param {Promise} action
         * @param {function():void} onError
         * @param {string} successMessage
         * @returns {Promise}
         */
        process: (
          action,
          onError = function () {},
          successMessage = i18n.global.t(
            'helpers.notify_mixin.toasted_message.show_success',
          ),
        ) => {
          this.notifyMethods().clearFieldErrors()

          return action
            .then(() => this.notifyMethods().showSuccess(successMessage))
            .catch((error) => {
              AxiosHelper.processError(
                error,
                (message) => {
                  this.notifyMethods().showError(message)
                },
                (data) => {
                  this.notifyMethods().setFieldErrors(data)
                  onError()
                },
              )
            })
        },

        /**
         * Update errors data-object.
         * @param {Object} data
         */
        setFieldErrors: (data = {}) => {
          this.errors = data
        },

        /**
         * Clear errors object
         */
        clearFieldErrors: () => {
          this.notifyMethods().setFieldErrors()
        },
      }
    },

    /**
     * @param {Object} data
     * @param {string} path
     * @returns {Object|string|null}
     */
    getFieldErrorByPath: (data, path) => {
      if (Array.isArray(path)) {
        for (let i = 0; i < path.length; i++) {
          const result = get(data, path[i], null)

          if (result) {
            return result
          }
        }
      }

      return get(data, path, null)
    },
  },
})
