import { RESET_STATE } from '@/helpers/ResetState'
import { cloneDeep } from 'lodash'
import createState from './state'

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setOnlineLessons(state, payload) {
    state.onlineLessons = payload
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   */
  setTheOnlineLesson(state, payload) {
    state.theOnlineLesson = payload
    state.theOnlineLessonInitial = cloneDeep(payload)

    state.isModifiedTheOnlineLessonExceptRepeatTypeFields = false
  },

  /**
   * @param {Object} state
   * @param {boolean} payload
   */
  setTheOnlineLessonIsRunning(state, payload) {
    state.isOnlineLessonRunning = payload
  },

  /**
   * @param {Object} state
   * @param {Object} args
   * @param {string} args.key
   * @param {number|string|boolean|Array|null} args.value
   */
  setTheOnlineLessonValue(state, { key, value }) {
    state.theOnlineLesson[key] = value

    if (
      ![
        'repeatType',
        'repeatBeginDate',
        'repeatEndDate',
        'copyTheme',
      ].includes(key)
    ) {
      state.isModifiedTheOnlineLessonExceptRepeatTypeFields = true
    }
  },

  /**
   * @param {Object} state
   * @param {Object} school
   */
  addTheAffiliation(state, school) {
    const affiliation = {
      school,
      grades: [],
      managers: [],
      subjects: [],
      educationTypes: [],
    }

    state.theOnlineLesson.affiliations.push(affiliation)

    state.isModifiedTheOnlineLessonExceptRepeatTypeFields = true
  },

  /**
   * @param {Object} state
   * @param {Object} school
   */
  removeTheAffiliation(state, school) {
    const schoolIndex = state.theOnlineLesson.affiliations.findIndex(
      (item) => item.school.id === school.id,
    )

    state.theOnlineLesson.affiliations.splice(schoolIndex, 1)

    state.isModifiedTheOnlineLessonExceptRepeatTypeFields = true
  },

  /**
   * @param {Object} state
   * @param {Object} args
   * @param {number} args.index
   * @param {Object} args.value
   */
  setTheAffiliationValue(state, { index, value }) {
    state.theOnlineLesson.affiliations[index] = value

    state.isModifiedTheOnlineLessonExceptRepeatTypeFields = true
  },

  /**
   * @param {Object} state
   * @param {Object} args
   * @param {string} args.key
   * @param {Array} args.value
   */
  setOption(state, { key, value }) {
    state.option[key] = value
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setTheOnlineLessonSignups(state, payload) {
    state.signups = payload
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setTheOnlineLessonVisitors(state, payload) {
    state.visitors = payload
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setTheOnlineLessonWatchers(state, payload) {
    state.watchers = payload
  },

  /**
   * @param {Object} state
   * @param {number} payload
   */
  setTheOnlineLessonCountByRepeatBeginEndDate(state, payload) {
    state.newRepeatBeginEndDateCount = payload
  },

  setTheOnlineLessonBigBlueButtonIsDisabled(state, value) {
    state.isBigBlueButtonLessonDisabled = value
  },

  setTheOnlineLessonStudents(state, payload) {
    state.theOnlineLesson.students = payload
  },

  setTheOnlineLessonStreams(state, payload) {
    state.theOnlineLesson.streams = payload
  },

  /**
   * @param {Object} state
   * @param {boolean} payload
   */
  setTheOnlineLessonIsModifiedExceptRepeatEndDate(state, payload) {
    state.isModifiedTheOnlineLessonExceptRepeatTypeFields = payload
  },
}
