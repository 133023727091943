<template>
  <UiKitModal2 :name="modalName" width="70%">
    <img :class="$style.image" :src="src" :alt="fileName" />
  </UiKitModal2>
</template>

<script>
import UiKitModal2 from '@/ui/UiKitModal2.vue'
import { defineComponent } from 'vue'
import { useVfm } from 'vue-final-modal'

export default defineComponent({
  name: 'ModalViewImage',

  components: {
    UiKitModal2,
  },

  props: {
    src: {
      type: String,
      default: null,
    },
  },

  setup() {
    const vfm = useVfm()

    return {
      vfm,
    }
  },

  data() {
    return {
      modalName: Symbol('ModalViewImage'),
    }
  },

  computed: {
    fileName() {
      return this.src?.name ?? null
    },
  },

  methods: {
    /**
     * @public
     */
    show() {
      this.vfm.open(this.modalName)
    },
  },
})
</script>

<style lang="scss" module>
.image {
  width: 100%;
  height: 90vh;
  object-fit: contain;
}
</style>
