const routes = [
  {
    path: '/teenology',
    name: 'admin-teenology',
    meta: {
      accessControlGroup: 'teenology',
    },
    component: () => import('@/pages/teenology/Teenology.vue'),
  },
]

export default routes
