<template>
  <li class="mb-1">
    <Tippy
      ref="dropdown"
      :animate-fill="false"
      :z-index="10"
      animation="fade"
      interactive
      placement="bottom"
      size="large"
      trigger="click"
      class="cursor-pointer"
    >
      <span>{{ getMetricsName(tracker.trackerType.id) }}</span>

      <template #content>
        <span>{{ tracker.value }}</span>
      </template>
    </Tippy>
  </li>
</template>

<script setup lang="ts">
import type { MetricsTracker } from '@/types/MetricsTracker.ts'
import Constants from 'Constants'
import { useI18n } from 'vue-i18n'
import { Tippy } from 'vue-tippy'

defineProps<{
  tracker: MetricsTracker
}>()

const { t } = useI18n()

const metricsNames = {
  [Constants.metricsServiceName.YANDEX]: 'yandex',
}

type IdParam =
  (typeof Constants.metricsServiceName)[keyof typeof Constants.metricsServiceName]

const getMetricsName = (id: IdParam) => t(`const.metrics.${metricsNames[id]}`)
</script>
