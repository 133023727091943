import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setLogs(state, payload) {
    state.logs = payload
  },
}
