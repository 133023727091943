<template>
  <LoaderFullScreen v-if="isLoading" />

  <template v-else>
    <Theme />

    <router-view v-if="isRendered.page" />
  </template>

  <ModalsContainer />
</template>

<script>
import LoaderFullScreen from '@/components/LoaderFullScreen.vue'
import Theme from '@/components/Theme.vue'
import { initTrackers } from '@/helpers/trackers/init'
import { ModalsContainer } from 'vue-final-modal'

export default defineComponent({
  name: 'App',

  components: {
    Theme,
    LoaderFullScreen,
    ModalsContainer,
  },

  data() {
    return {
      isLoadingFonts: true,
    }
  },

  head() {
    return {
      titleTemplate: (title) => (title ? `${title} | IBLS` : 'IBLS'),
    }
  },

  computed: {
    ...mapState({
      isLoadingTheme: (state) => state.admin.theme.isLoading,
    }),

    ...mapState('admin/navigation', [
      'textsLoaded',
    ]),

    isLoading() {
      return this.isLoadingTheme || this.isLoadingFonts
    },

    isTextsLoaded() {
      return this.textsLoaded
    },

    isAuthRequired() {
      return (
        this.$route.meta.requiresAuth === true ||
        typeof this.$route.meta.requiresAuth === 'undefined'
      )
    },

    isRendered() {
      return {
        page: !this.isAuthRequired || this.isTextsLoaded,
      }
    },
  },

  created() {
    this.init()
  },

  methods: {
    ...mapActions('admin/theme', [
      'getTheme',
    ]),

    ...mapActions('admin/navigation', [
      'fetchProfile',
    ]),

    async init() {
      await this.initFonts()

      if (this.isAuthRequired) {
        await Promise.all([
          this.getTheme(),
          this.fetchProfile(),
          initTrackers(),
        ])
      }
    },

    async initFonts() {
      try {
        await document.fonts.ready
      } finally {
        this.isLoadingFonts = false
      }
    },
  },
})
</script>

<style lang="scss">
@import '~@/app';
</style>
