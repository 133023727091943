import { ApiSMSLog } from 'ApiRest/Api/SMSLog/Logs'

export default {
  /**
   * @param {Object} args
   * @param {Function} args.commit
   */
  resetState({ commit }) {
    commit('resetState')
  },

  /**
   * @param {Object|undefined} args
   * @param {Function} args.commit
   * @returns {Promise}
   */
  async fetchLogs({ commit }) {
    const { data } = await ApiSMSLog.get()

    commit('setLogs', data)
  },
}
