<template>
  <UiKitModal2 :name="name" width="80%" height="80vh" title="Редактирование">
    <ImageEditor
      :image="file"
      :image-src="imageSrc"
      @save-edited-image="onSaveEditedImage"
    />
  </UiKitModal2>
</template>

<script setup lang="ts">
import ImageEditor from '@/components/ImageEditor.vue'
import { showToast } from '@/helpers/toast'
import UiKitModal2 from '@/ui/UiKitModal2.vue'
import ApiUpload from 'Api/Upload'
import { computed } from 'vue'
import { useVfm } from 'vue-final-modal'

type fileType = Record<string, unknown> | undefined

interface Props {
  file: fileType
  imageSrc: string | undefined
  isPrivate?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isPrivate: true,
})

const emit = defineEmits<{
  (e: 'upload-file', data: Blob): void
}>()

const vfm = useVfm()

const name = Symbol('ModalEditFileUpload')

const originalFileId = computed<number | undefined>(() => {
  if (props.file?.originalFile === 'undefined') {
    return props.file?.id
  }

  return typeof props.file?.originalFile === 'object'
    ? props.file?.originalFile?.id ?? undefined
    : undefined
})

function show(): void {
  vfm.open(name)
}

function hide(): void {
  vfm.close(name)
}

async function onSaveEditedImage(file: Blob): Promise<void> {
  try {
    const { data } = await ApiUpload.sendFile(
      file,
      () => {},
      props.isPrivate,
      originalFileId.value,
    )

    emit('upload-file', data)
    hide()
    showToast('Файл добавлен в поле с ответом')
  } catch (e: unknown) {
    if (e instanceof Error) {
      showToast(e.message, 'error')
    } else {
      showToast('Неизвестная ошибка', 'error')
    }
  }
}

defineExpose({
  show,
})
</script>
