<script setup lang="ts">
defineOptions({
  name: 'ToastContent',
})

defineProps({
  message: {
    type: String,
    default: '',
  },
})
</script>

<template>
  <div>
    <div v-html="message"></div>
  </div>
</template>
